@import 'src/design-system/styles/typography.mixins';
@import 'src/design-system/styles/layout-variables';

.content {
  height: 100%;
}

.scrollable {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px 16px;
  grid-template-areas:
    'bannerList1 bannerList1'
    'matchOfDay matchOfDay'
    'interestingMatches interestingMatches';
  padding: $content-indent;
  padding-bottom: 0;
  overflow-x: hidden;
}

.bannerList1 {
  grid-area: bannerList1;
}

.matchOfTheDay {
  grid-area: matchOfDay;
  width: 100%;
}

.aviatorBanner {
  width: 100%;
  height: 154px;
  border-radius: 8px;
  overflow: hidden;

  & a {
    display: block;
    width: 100%;
    height: 100%;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
